
import { storeToRefs } from "pinia";
import { defineComponent, nextTick, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import QrcodeVue from "qrcode.vue";
import { Toast } from "bootstrap";

import { useAppStore } from "@/store/appStore";

import { Report } from "@/models/Report";
import { useAuthStore } from "@/store/authStore";
import router from "@/router";
import axiosApi from "@/services/axios";

export default defineComponent({
  name: "TestView",
  components: {
    QrcodeVue,
  },
  setup() {
    const route = useRoute();
    const { isLoggedIn } = storeToRefs(useAuthStore());
    const appStore = useAppStore();

    const toastAlert = ref();

    const isLoadingPdf = ref(false);
    const isSendingEmail = ref(false);
    const emailSended = ref(false);

    const {
      companyName,
      companyDocTitle,
      companyEmail,
      companyWeb,
      companyAddress,
    } = storeToRefs(appStore);
    const report = ref({} as Report);

    const getReport = async (id: string) => {
      report.value = await appStore.getReport(id);
    };

    const getReportWithTokenQR = async (token: string) => {
      const res = await appStore.getReportQR(route.params.token as string);
      if (!res) {
        console.log(res.message);
        return;
      }

      // Ok, report recibido
      report.value = res.report;
    };

    const generatePDF = () => {
      if (isLoadingPdf.value) {
        return;
      }

      isLoadingPdf.value = true;
      window.location.href = axiosApi.defaults.baseURL + `/qr/report/${report.value.qr_token}/pdf`;
    };

    // TODO: Pasar el toast a un servicio global o crear componente reutilizable
    const showAlertEmail = (success: boolean) => {
      emailSended.value = success;
      const alert = new Toast(toastAlert.value, { autohide: true });
      nextTick(() => alert.show());
    };

    const sendReportEmail = async () => {
      isSendingEmail.value = true;
      const res = await appStore.sendReportEmail(report.value);
      isSendingEmail.value = false;

      showAlertEmail(res.ok);
    };

    watchEffect(() => {
      if (route.params.id) {
        getReport(route.params.id as string);
      }

      if (route.params.token) {
        getReportWithTokenQR(route.params.token as string);
      }
    });

    return {
      isLoggedIn,
      isLoadingPdf,
      isSendingEmail,
      emailSended,
      toastAlert,
      companyName,
      companyDocTitle,
      companyEmail,
      companyWeb,
      companyAddress,
      report,
      generatePDF,
      sendReportEmail,
    };
  },
});
