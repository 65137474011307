
import { defineComponent, ref, toRaw, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/appStore";
import { useUserStore } from "@/store/userStore";

import { Report } from "@/models/Report";
import { Patient } from "@/models/Patient";
import { User } from "@/models/User";

import TestForm from "@/views/test/components/TestForm.vue";

export default defineComponent({
  name: "TestAdd",
  components: {
    TestForm,
  },
  setup() {
    const router = useRouter();
    const appStore = useAppStore();
    const { perfil } = storeToRefs(useUserStore());
    const report = ref({
      type_es: "Deteccion cualitativa Antígeno del SARS-CoV-2",
      type_en: "Qualitative detection SARS-CoV-2 antigen",
      note_es:
        "Test de antígenos efectuado con PANBIO COVID 19 Ag de ABBOTT, con una sensibilidad >93% y una especifidad del 99% (Según informe de Instituto de Salud Carlos III)",
      note_en:
        "Antigen test carried out with PANBIO COVID 19 Ag from ABBOTT, with a sensitivity> 93% and a specificity of 99% (According to the Carlos III Health Institute report)",
      observation_es:
        "Los resultados negativos deben combinarse con observaciones clínicas, historial del paciente e información epidemiológica.",
      observation_en:
        "Negative results should be combined with clinical observations, patient history, and epidemiological information.",
      date_sample: new Date().toISOString().slice(0, 10),
      time_sample: new Date().toLocaleTimeString("es", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
      date_analysis: new Date().toISOString().slice(0, 10),
      time_analysis: new Date().toLocaleTimeString("es", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
      date_validation: new Date().toISOString().slice(0, 10),
      doctor: {} as User,
      patient: {} as Patient,
    } as Report);
    const errorSave = ref("");

    const saveReport = async () => {
      const res = await appStore.newReport(toRaw(report.value));
      if (!res.ok) {
        console.log("Error al guardar el reporte", report.value);
        errorSave.value = res.message;
        return;
      }

      // Guardado correctamente, enviar a la pagina de visualizar test
      router.replace(`/${res.report.id}`);
    };

    watchEffect(() => {
      report.value.doctor = toRaw(perfil.value);
    });

    return {
      report,
      saveReport,
    };
  },
});
