import { defineStore } from "pinia";
import axiosApi from "@/services/axios";

import { User } from "@/models/User";

export const useUserStore = defineStore('user', {
    state: () => ({
        isLoading: false,
        perfil: {} as User
    }),

    getters: {
        fullName: ({ perfil }) => `${perfil.nombre}`
    },

    actions: {
        async getProfile() {
            this.isLoading = true;
            const profile = await axiosApi.get('user/profile')
                .then(res => res.data)
                .catch(error => false);

            if (profile) {
                this.perfil = profile;
            }

            this.isLoading = false;
        }
    }
})
