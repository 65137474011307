
import { Report } from "@/models/Report";
import { useAppStore } from "@/store/appStore";
import { useDebounce } from "@vueuse/core";
import { defineComponent, onBeforeMount, ref, watch } from "vue";

export default defineComponent({
  name: "Home",
  setup() {
    const appStore = useAppStore();
    const reports = ref([] as Report[]);
    const searchInput = ref("");
    const searchDebounced = useDebounce(searchInput, 500);

    const getReports = async (search: string = "") => {
      reports.value = await appStore.getReports(search);
    };

    // Search patient, debounced
    watch(searchDebounced, (newValue, prevValue) => {
      console.log("Buscando paciente", newValue);
      getReports(newValue);
    });

    onBeforeMount(() => {
      getReports();
    });

    return {
      searchInput,
      reports,
    };
  },
});
