import router from "@/router";
import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

import auth from "@/services/api/auth";
import { TokenStorage } from "@/services/tokenStorage";

import { useUserStore } from "./userStore";

export const useAuthStore = defineStore('auth', {

    state: () => useStorage('auth_state', {
        isLoggedIn: false,
        loginError: false
    }),

    getters: {
        isAuthenticated:(state) => {
            const existToken = localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN) ?? false;
            return state.isLoggedIn && existToken;
        }
    },

    actions: {
        async login(dni: string, password: string) {
            const userStore = useUserStore();

            const isSuccessLogin = await auth.login(dni, password)
                .then(success => {
                    this.isLoggedIn = success;
                    this.loginError = !success;

                    return success;
                })
                .catch(error => {
                    this.loginError = true;
                    return false;
                });
            
            // Cargar perfil si el login es correcto
            if (isSuccessLogin) {
                await userStore.getProfile();
            }

            return isSuccessLogin;
        },

        logout() {
            TokenStorage.clear();
            this.isLoggedIn = false;

            // Clear Stores
            useUserStore().$reset();

            router.replace('/login');
        }
    }
})
