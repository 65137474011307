
import { defineComponent, ref, watchEffect, toRaw } from "vue";
import TestForm from "@/views/test/components/TestForm.vue";
import { useRoute, useRouter } from "vue-router";
import { Report } from "@/models/Report";
import { useAppStore } from "@/store/appStore";

export default defineComponent({
  name: "TestModify",
  components: {
    TestForm,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const appStore = useAppStore();
    const report = ref({} as Report);

    const isSaving = ref(false);
    const isDeleting = ref(false);
    const errorEdit = ref("");

    const getReport = async (id: string) => {
      report.value = await appStore.getReport(id);
    };

    const saveReport = async () => {
      isSaving.value = true;
      const res = await appStore.editReport(toRaw(report.value));
      isSaving.value = false;

      if (!res.ok) {
        console.log("Error al guardar", res.message);
        errorEdit.value = res.message;
        return;
      }

      // Guardado correctamente
      console.log("Guardado correctamente", res.report);
      router.push(`/${report.value.id}`);
    };

    const deleteReport = async () => {
      if (!confirm('Esta seguro que desea eliminar el analisis?')) {
        return;
      }

      isDeleting.value = true;
      const res = await appStore.deleteReport(report.value.id);
      isDeleting.value = false;

      if (!res.ok) {
        // Algo fallo al borrar el report
        console.log(res.message);
        return;
      }

      router.replace('/');
    }

    watchEffect(() => {
      getReport(route.params.id as string);
    });

    return {
      report,
      isSaving,
      isDeleting,
      saveReport,
      deleteReport,
      errorEdit,
    };
  },
});
