<template>
  <div aria-live="polite" aria-atomic="true" class="sticky-top">
    <div
      class="toast-container position-relative top-0 start-50 translate-middle-x p-3"
    >
      <!-- Then put toasts within -->
      <div
        class="toast"
        :class="[emailSended ? 'bg-success' : 'bg-danger']"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        ref="toastAlert"
      >
        <div class="d-flex">
          <div class="toast-body text-white">
            <i class="bi bi-envelope-fill"></i>
            {{
              emailSended
                ? "Email enviado correctamente"
                : "Ocurrio un error al enviar el email"
            }}
            <i
              class="bi"
              :class="[emailSended ? 'bi-check-lg' : 'bi-x-lg']"
            ></i>
          </div>
          <button
            type="button"
            class="btn-close me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="actions">
      <div class="actions__left">
        <router-link
          v-if="isLoggedIn"
          class="boton boton--principal"
          type="button"
          :disabled="isSendingEmail"
          :to="`/${report.id}/edit`"
        >
          <i class="bi bi-pencil-fill"></i> Editar analisis
        </router-link>
      </div>
      <div class="actions__right">
        <button
          type="button"
          class="ms-2 boton boton--gris"
          @click="generatePDF"
          :disabled="isLoadingPdf"
        >
          <i v-if="!isLoadingPdf" class="bi bi-arrow-down"></i>
          <div v-if="isLoadingPdf" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>

          {{ isLoadingPdf ? 'Descargando PDF' : 'Descargar PDF' }}
        </button>
        <button
          v-if="isLoggedIn"
          type="button"
          class="ms-2 boton boton--gris boton--disabled"
          @click="sendReportEmail"
          :disabled="report.patient?.email === '' || isSendingEmail"
        >
          <i
            v-if="report.patient?.email === ''"
            class="bi bi-x-lg text-danger me-2"
          ></i>
          {{
            isSendingEmail
              ? "Enviando..."
              : report.patient?.email !== ""
              ? "Enviar por email"
              : "El cliente no tiene email asignado"
          }}
        </button>
      </div>
    </div>
  </div>

  <div v-if="report?.id" class="view">
    <div class="view__header">
      <div class="view__artclinic">
        <div class="view__logo">
          <img src="@/assets/logo-pdf.jpg" alt="" />
        </div>
        <div class="view__content">
          <h2>{{ companyDocTitle }}</h2>
          <h5>Análisis <em>Analytics</em></h5>
          <h6 class="mb-0">
            {{ report.type_es }} <small>{{ report.type_en }}</small>
          </h6>
        </div>
      </div>
      <div v-if="report.qr_link !== ''" class="view__qr">
        <!-- <img src="@/assets/site/qrcode.svg" alt="" /> -->
        <qrcode-vue :value="report.qr_link" :size="160" level="L" />
      </div>
    </div>
    <div class="view__datos">
      <div class="view-box me-5">
        <h2>Datos del análisis <span>Analytic data</span></h2>
        <ul>
          <li>
            <label>Nº Analisis <span>N. Analytic</span></label>
            <div>{{ report.id }}</div>
          </li>
          <li>
            <label>Fecha de la muestra <span>Sample date</span></label>
            <div>{{ report.date_sample }}</div>
          </li>
          <li>
            <label>Hora de la muestra <span>Sample Hour</span></label>
            <div>{{ report.time_sample }}</div>
          </li>
          <li>
            <label>Fecha de análisis <span>Analityc date</span></label>
            <div>{{ report.date_analysis }}</div>
          </li>
          <li>
            <label
              >Facultativo prescriptor / Colegiado
              <span>Optional prescriber / Collegiate</span></label
            >
            <div>
              {{ report.doctor.cargo }}. {{ report.doctor.nombre }} /
              {{ report.doctor.num_colegiado ?? "SIN_ASIGNAR" }}
            </div>
          </li>
        </ul>
      </div>

      <div class="view-box view-box--paciente">
        <h2>Datos personales <span>Personal Information</span></h2>
        <ul>
          <li>
            <label>Nombre <span>Name</span></label>
            <div>
              {{ report.patient.nombre }} {{ report.patient.apellido_1 }}
              {{ report.patient.apellido_2 }}
            </div>
          </li>
          <li>
            <label>DNI <span>ID. Card</span></label>
            <div>{{ report.patient.dni }}</div>
          </li>
          <li>
            <label>Fecha de nacimiento <span>Birth date</span></label>
            <div>{{ report.patient.fecha_naci }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="view__analisis">
      <table class="table table-bordered align-middle resultados">
        <thead>
          <tr>
            <th scope="col">Test</th>
            <th scope="col">Resultado / Diagnostic</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <h4 class="my-0 fw-bold">{{ report.type_es }}</h4>
              <h4 class="my-0 fst-italic">{{ report.type_en }}</h4>
            </th>
            <td>
              <span v-if="report.resultado" class="badge badge--lg badge--red">
                <strong>POSITIVO</strong> / <em>POSITIVE</em>
              </span>
              <span v-else class="badge badge--lg badge--green">
                <strong>NEGATIVO</strong> / <em>NEGATIVE</em>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="view__observaciones">
      <div class="view-box">
        <h2>Observaciones <span>Observations</span></h2>
        <div class="view-box__content">
          <p>{{ report.observation_es }}</p>
          <p>
            <em>{{ report.observation_en }}</em>
          </p>
        </div>
      </div>
      <div class="view-box">
        <h2>Notas <span>Notes</span></h2>
        <div class="view-box__content">
          <p>{{ report.note_es }}</p>
          <p>
            <em>{{ report.note_en }}</em>
          </p>
        </div>
      </div>
    </div>

    <hr class="view-hr" />

    <div class="view__dr text-center">
      <div class="firma">
        <div class="firma__datos">
          <address>
            <strong
              >{{ report.doctor.cargo }}. {{ report.doctor.nombre }}</strong
            >
            <br />
            Colegidado / Collegiate:
            {{ report.doctor.num_colegiado ?? "NO_ASIGNADO" }} <br />
            {{ report.doctor.especialidad_es }} /
            <em>{{ report.doctor.especialidad_en }}</em>
            <br />
            Fecha de validación: {{ report.date_validation }}
          </address>
          <img src="@/assets/site/firma-carmen.jpg" alt="" />
        </div>

        <address class="firma__direccion">
          <div class="">
            <strong>{{ companyDocTitle }}</strong>
          </div>
          <div class="">
            Registro Sanitario Comunitat Valenciana /
            <em>Valencian Community (Spain) Health Registry: </em>
            <strong>9683</strong>
          </div>
          <div class="">
            <a href="mailto:info@artclinic.es">{{ companyEmail }}</a> -
            <a href="https://www.artclinic.es">{{ companyWeb }}</a> -
            {{ companyAddress }}
          </div>
        </address>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { storeToRefs } from "pinia";
import { defineComponent, nextTick, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import QrcodeVue from "qrcode.vue";
import { Toast } from "bootstrap";

import { useAppStore } from "@/store/appStore";

import { Report } from "@/models/Report";
import { useAuthStore } from "@/store/authStore";
import router from "@/router";
import axiosApi from "@/services/axios";

export default defineComponent({
  name: "TestView",
  components: {
    QrcodeVue,
  },
  setup() {
    const route = useRoute();
    const { isLoggedIn } = storeToRefs(useAuthStore());
    const appStore = useAppStore();

    const toastAlert = ref();

    const isLoadingPdf = ref(false);
    const isSendingEmail = ref(false);
    const emailSended = ref(false);

    const {
      companyName,
      companyDocTitle,
      companyEmail,
      companyWeb,
      companyAddress,
    } = storeToRefs(appStore);
    const report = ref({} as Report);

    const getReport = async (id: string) => {
      report.value = await appStore.getReport(id);
    };

    const getReportWithTokenQR = async (token: string) => {
      const res = await appStore.getReportQR(route.params.token as string);
      if (!res) {
        console.log(res.message);
        return;
      }

      // Ok, report recibido
      report.value = res.report;
    };

    const generatePDF = () => {
      if (isLoadingPdf.value) {
        return;
      }

      isLoadingPdf.value = true;
      window.location.href = axiosApi.defaults.baseURL + `/qr/report/${report.value.qr_token}/pdf`;
    };

    // TODO: Pasar el toast a un servicio global o crear componente reutilizable
    const showAlertEmail = (success: boolean) => {
      emailSended.value = success;
      const alert = new Toast(toastAlert.value, { autohide: true });
      nextTick(() => alert.show());
    };

    const sendReportEmail = async () => {
      isSendingEmail.value = true;
      const res = await appStore.sendReportEmail(report.value);
      isSendingEmail.value = false;

      showAlertEmail(res.ok);
    };

    watchEffect(() => {
      if (route.params.id) {
        getReport(route.params.id as string);
      }

      if (route.params.token) {
        getReportWithTokenQR(route.params.token as string);
      }
    });

    return {
      isLoggedIn,
      isLoadingPdf,
      isSendingEmail,
      emailSended,
      toastAlert,
      companyName,
      companyDocTitle,
      companyEmail,
      companyWeb,
      companyAddress,
      report,
      generatePDF,
      sendReportEmail,
    };
  },
});
</script>
<style lang="scss"></style>
