<template>
  <section>
    <div class="container">
      <div class="actions mb-3">
        <div class="actions__left">
          <router-link to="/nuevo" class="boton boton--principal">
            <i class="bi bi-plus-circle-fill me-2"></i> Añadir nuevo analisis
          </router-link>
        </div>
        <div class="actions__right">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Buscar paciente..."
            v-model="searchInput"
          />
        </div>
      </div>

      <div class="card card--card">
        <div class="card__header">
          Listado de Test realizados
        </div>
        <div class="card__body">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Nº</th>
                  <th scope="col">Paciente</th>
                  <th scope="col">DNI</th>
                  <th scope="col">Fecha muestra</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="report in reports" :key="report.id">
                  <th>{{ report.id }}</th>
                  <td>
                    {{ report.patient.nombre }} {{ report.patient.apellido_1 }}
                    {{ report.patient.apellido_2 }}
                  </td>
                  <td>{{ report.patient.dni }}</td>
                  <td>
                    {{ report.date_analysis }}
                    {{ report.time_analysis }}
                  </td>
                  <td>
                    <div class="row-actions">
                      <div class="row-actions__item">
                        <router-link
                          :to="`/${report.id}`"
                          class="boton boton--principal boton--sm"
                        >
                          <i class="bi bi-eye-fill"></i> Ver
                        </router-link>
                      </div>
                      <div class="row-actions__item">
                        <router-link
                          :to="`/${report.id}/edit`"
                          class="boton boton--gris boton--sm"
                        >
                          <i class="bi bi-pencil-fill"></i> Editar
                        </router-link>
                      </div>
                      <!-- <div class="row-actions__item">
                        <router-link
                          to="/test"
                          class="boton boton--gris boton--sm"
                        >
                          <i class="bi bi-file-pdf-fill"></i> PDF
                        </router-link>
                      </div> -->

                      <div class="row-actions__item">
                        <router-link
                          to="/test"
                          class="boton boton--gris boton--sm"
                        >
                          <i class="bi bi-forward-fill"></i> Email
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Report } from "@/models/Report";
import { useAppStore } from "@/store/appStore";
import { useDebounce } from "@vueuse/core";
import { defineComponent, onBeforeMount, ref, watch } from "vue";

export default defineComponent({
  name: "Home",
  setup() {
    const appStore = useAppStore();
    const reports = ref([] as Report[]);
    const searchInput = ref("");
    const searchDebounced = useDebounce(searchInput, 500);

    const getReports = async (search: string = "") => {
      reports.value = await appStore.getReports(search);
    };

    // Search patient, debounced
    watch(searchDebounced, (newValue, prevValue) => {
      console.log("Buscando paciente", newValue);
      getReports(newValue);
    });

    onBeforeMount(() => {
      getReports();
    });

    return {
      searchInput,
      reports,
    };
  },
});
</script>
