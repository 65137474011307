import axios from "@/services/axios";
import { TokenStorage } from "../tokenStorage";

export default {

  login(email: string, password: string) {
    return axios.post('/auth/login', {
      email,
      password
    }).then(res => {
      if (res.data?.ok === false) {
        return false;
      }

      // Login ok, guardamos token en localstorage
      TokenStorage.storeToken(res.data.token);
      return true;
    });
  },
};
