<template>
  <div class="formulario">
    <div class="row">
      <div class="col-md-5 ">
        <fieldset>
          <h5>
            Datos del Test de Antígenos
          </h5>
          <div class="mb-3">
            <div class="mb-3">
              <label class="form-label">Numero de Análisis</label>
              <input
                type="text"
                class="form-control"
                placeholder="A800 (Autoincrement)"
                :value="reportEdit.id"
                disabled
              />
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Tipo de Análisis</label>
            <input
              type="text"
              class="form-control"
              v-model="reportEdit.type_es"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Tipo de Análisis (ENG)</label>
            <input
              type="text"
              class="form-control"
              v-model="reportEdit.type_en"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Nota del test</label>
            <textarea
              type="textarea"
              class="form-control"
              rows="4"
              v-model="reportEdit.note_es"
            ></textarea>
          </div>
          <div class="mb-3">
            <label class="form-label">Nota del test (ENG)</label>
            <textarea
              type="textarea"
              class="form-control"
              rows="4"
              v-model="reportEdit.note_en"
            ></textarea>
          </div>
          <div class="mb-3">
            <label class="form-label">Observaciones</label>
            <textarea
              type="textarea"
              class="form-control"
              rows="4"
              v-model="reportEdit.observation_es"
            ></textarea>
          </div>
          <div class="mb-3">
            <label class="form-label">Observaciones (ENG)</label>
            <textarea
              type="textarea"
              class="form-control"
              rows="4"
              v-model="reportEdit.observation_en"
            ></textarea>
          </div>

          <div class="row">
            <div class="mb-3 col-md-6">
              <div class="mb-3">
                <label class="form-label">Fecha de muestra</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="reportEdit.date_sample"
                />
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="mb-3">
                <label class="form-label">Hora de muestra</label>
                <input
                  type="time"
                  class="form-control"
                  placeholder="Hora actual"
                  v-model="reportEdit.time_sample"
                />
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="mb-3">
                <label class="form-label">Fecha de Análisis</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="reportEdit.date_analysis"
                />
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="mb-3">
                <label class="form-label">Hora de Análisis</label>
                <input
                  type="time"
                  class="form-control"
                  v-model="reportEdit.time_analysis"
                />
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="mb-3">
                <label class="form-label">Fecha de Validación</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="reportEdit.date_validation"
                />
              </div>
            </div>
            <div class="mb-3 col-md-6"></div>
          </div>
        </fieldset>
        <fieldset>
          <h5>
            Datos del Factultativo
          </h5>

          <div class="mb-3">
            <label class="form-label">Doctor</label>
            <input
              type="text"
              class="form-control"
              :value="reportEdit.doctor.nombre"
              disabled
            />
          </div>

          <div class="mb-3">
            <label class="form-label">Colegiado Num</label>
            <input
              type="text"
              class="form-control"
              :value="reportEdit.doctor.num_colegiado"
              disabled
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Cargo</label>
            <input
              type="text"
              class="form-control"
              :value="reportEdit.doctor.cargo"
              disabled
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Cargo (ENG)</label>
            <input
              type="text"
              class="form-control"
              :value="reportEdit.doctor.cargo"
              disabled
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Especialidad</label>
            <input
              type="text"
              class="form-control"
              :value="reportEdit.doctor.especialidad_es"
              disabled
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Especialidad (ENG)</label>
            <input
              type="text"
              class="form-control"
              :value="reportEdit.doctor.especialidad_en"
              disabled
            />
          </div>
        </fieldset>
      </div>
      <div class="offset-md-1 col-md-6">
        <fieldset>
          <h5>
            Datos personales
          </h5>
          <div class="mb-3">
            <div class="mb-3">
              <label class="form-label">Nombre</label>
              <input
                type="text"
                class="form-control"
                v-model="reportEdit.patient.nombre"
              />
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <label for="inputApellidoUno" class="form-label"
                >Primer Apellido</label
              >
              <input
                type="text"
                class="form-control"
                id="inputApellidoUno"
                aria-describedby="Primer Apellido"
                v-model="reportEdit.patient.apellido_1"
              />
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <label for="inputApellidoDos" class="form-label"
                >Segundo Apellido</label
              >
              <input
                type="text"
                class="form-control"
                id="inputApellidoDos"
                aria-describedby="Apellido"
                v-model="reportEdit.patient.apellido_2"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="mb-3">
                <label class="form-label">DNI</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportEdit.patient.dni"
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="mb-3">
                <label class="form-label">Fecha de nacimiento</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="reportEdit.patient.fecha_naci"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label">Resultado</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="radioResultado"
                    :checked="reportEdit.resultado === false"
                    @change="changeResultado(false)"
                  />
                  <label class="form-check-label text-success">
                    Negativo
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="radioResultado"
                    :checked="reportEdit.resultado === true"
                    @change="changeResultado(true)"
                  />
                  <label class="form-check-label text-danger">
                    Positivo
                  </label>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <h5>Datos para el envío</h5>

          <div class="mb-3">
            <label class="form-label">Correo Electrónico</label>
            <input
              type="text"
              class="form-control"
              v-model="reportEdit.patient.email"
            />
            <div id="emailHelp" class="form-text">
              Enviamos aquí el resultado del test
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Report } from "@/models/Report";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "TestForm",
  data() {
    return {
      date: new Date().toISOString().slice(0, 10),
    };
  },
  props: {
    isEditMode: {
      default: true,
    },
    report: {
      default: {} as Report,
    },
  },
  setup(props) {
    const reportEdit = ref(props.report);

    watch(props, () => {
      reportEdit.value = props.report;
    });

    const changeResultado = (resultado: boolean) => {
      reportEdit.value.resultado = resultado;
    };

    return {
      reportEdit,
      changeResultado,
    };
  },
});
</script>
<style lang=""></style>
