
import router from "@/router";
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/authStore";
import { useAppStore } from "@/store/appStore";

export default defineComponent({
  name: "Login",
  setup() {
    const authStore = useAuthStore();
    const { loginError } = storeToRefs(authStore);
    const { companyName } = storeToRefs(useAppStore());

    const email = ref('');
    const password = ref('');

    const login = async () => {
      const ok = await authStore.login(email.value, password.value);
      if (ok) {
        router.replace('/');
      } else {
        email.value = '';
        password.value = '';
      }
    }

    return {
      companyName,
      email,
      password,
      loginError,
      login
    }
  }
});
