
import { Report } from "@/models/Report";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "TestForm",
  data() {
    return {
      date: new Date().toISOString().slice(0, 10),
    };
  },
  props: {
    isEditMode: {
      default: true,
    },
    report: {
      default: {} as Report,
    },
  },
  setup(props) {
    const reportEdit = ref(props.report);

    watch(props, () => {
      reportEdit.value = props.report;
    });

    const changeResultado = (resultado: boolean) => {
      reportEdit.value.resultado = resultado;
    };

    return {
      reportEdit,
      changeResultado,
    };
  },
});
