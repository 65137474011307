import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import authMiddleware from "./middleware/authMiddleware";
import interceptor from "@/services/interceptor";

import Home from "@/views/Home.vue";
import TestAdd from "@/views/test/TestAdd.vue";
import Login from "@/views/login/Login.vue";
import TestView from "@/views/test/TestView.vue";
import TestEdit from "@/views/test/TestEdit.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Inicio",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/nuevo",
    name: "Nuevo Test de Antígenos",
    component: TestAdd,
  },
  {
    path: "/:id/edit",
    name: "Modificar Test",
    component: TestEdit,
  },
  {
    path: "/:id",
    name: "Test de Antígenos",
    component: TestView,
  },
  {
    path: "/qr/report/:token",
    name: "QRREPORT",
    component: TestView,
    meta: {
      public: true
    }
  },

  // Default Route
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authMiddleware);

export default router;
