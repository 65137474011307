import axiosApi from "./axios";
import { useAuthStore } from "@/store/authStore";
import { TokenStorage } from "./tokenStorage";

export default {

  init() {

    const authStore = useAuthStore();

    // Añadir token a las peticiones de api, solo a las rutas que no son de auth
    axiosApi.interceptors.request.use((request: any) => {

      // añadir solo cuando la ruta sea distinta a /auth
      if (!request.url?.startsWith("/auth")) {
        request.headers.Authorization = TokenStorage.getAuthentication();
      }

      return request;
    });

    // Interceptar llamadas no authorizadas y realizar logout del usuario
    axiosApi.interceptors.response.use(
      (response: any) => {
        // Return successful response
        return response;
      },
      (error: any) => {
        // Si no es un error de autentificacion, devolvemos para manejar error normal.
        if (error.response.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }

        // Si es un error de autentificacion, hacemos logout del usuario
        authStore.logout();

        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
  },
};
