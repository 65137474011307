import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import interceptor from "./services/interceptor";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "@/scss/main.scss";

const app = createApp(App)
  .use(createPinia())
  .use(router);

// Iniciar Interceptor peticiones api axios
interceptor.init();

app.mount("#app");