<template>
  <div class="wrapper-login">
    <div class="login-box">
      <div class="login-box__content">
        <h1 class="login-box__h1">{{ companyName }}</h1>
        <h2 class="login-box__h2">Administración</h2>

        <div v-if="loginError" class="alert alert-danger">
          oopss! usuario o contraseña, incorrectos.
        </div>

        <div class="login-box__login">
          <div class="form-floating mb-3">
            <input
              type="email"
              class="form-control"
              id="floatingInput"
              placeholder="Correo electrónico"
              v-model="email"
            />
            <label for="floatingInput">Email</label>
          </div>
          <div class="form-floating mb-3">
            <input
              type="password"
              class="form-control"
              id="floatingPassword"
              placeholder="Contraseña"
              v-model="password"
            />
            <label for="floatingPassword">Contraseña</label>
          </div>
          <div class="mb-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Guardar la contraseña
              </label>
            </div>
          </div>
          <div class="login-box__actions">
            <button
              class="boton boton--principal boton--block mb-2"
              @click="login"
            >
              Acceso
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import router from "@/router";
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/authStore";
import { useAppStore } from "@/store/appStore";

export default defineComponent({
  name: "Login",
  setup() {
    const authStore = useAuthStore();
    const { loginError } = storeToRefs(authStore);
    const { companyName } = storeToRefs(useAppStore());

    const email = ref('');
    const password = ref('');

    const login = async () => {
      const ok = await authStore.login(email.value, password.value);
      if (ok) {
        router.replace('/');
      } else {
        email.value = '';
        password.value = '';
      }
    }

    return {
      companyName,
      email,
      password,
      loginError,
      login
    }
  }
});
</script>
<style lang=""></style>
