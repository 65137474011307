import { NavigationGuardWithThis } from "vue-router";
import { useAuthStore } from "@/store/authStore";

const authMiddleware: NavigationGuardWithThis<undefined> = (to, from, next) => {

  const authStore = useAuthStore();

  if (to.meta.public) {
    return next();
  }

  // Si no esta autenticado redirigir al login
  if (!to.path.startsWith("/login") && !authStore.isAuthenticated) {
    console.log('No estas auth');
    authStore.logout();
    return next("/login");
  }

  // Si esta autenticado y quiere ir al login, no permitir, redirigir a la pagina anterior
  if (to.path.startsWith("/login") && authStore.isAuthenticated) {
    return next(from);
  }

  next();
};

export default authMiddleware;
